import {httpClient} from "../utils";
export const taskcreateService={
  Addtask,
  GetQuestion
}

function Addtask(payload){
   return httpClient.callApi("POST", `/task/post`, payload);
}

function GetQuestion(questionId = -1, formId = 1){
  return httpClient.callApi("GET", `/questions/${formId}/${questionId}`, {}, false);
}