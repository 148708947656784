import {taskcreateConstants} from "../constants";
import {taskcreateService} from "../services";
import appinfo from "../clientsJSON";

export const taskcreateActions ={
    Addtask,
    GetQuestion
};

function Addtask(payload){
    return dispatch=>{
        dispatch(request());
        taskcreateService.Addtask(payload).then(
            message =>{
                dispatch(success(message));
            },
            error =>{
                dispatch(failure(error));
            }

        );
    };
    function request(){
        return{type:taskcreateConstants.ADDTASK_RQUEST};
    }
    function success(message){
        return {type:taskcreateConstants.ADDTASK_SUCCESS,message};
    }
    function failure(error){
     return{type:taskcreateConstants.ADDTASK_ERROR,error};
    }
}

function GetQuestion(questionId = -1, formId = appinfo.form){
    return dispatch=>{
        dispatch(request());
        taskcreateService.GetQuestion(questionId, formId).then(
            message => dispatch(success(message)), error => dispatch(failure(error))
        );
    };
    function request(){
        return{type:taskcreateConstants.QUESTION_REQUEST};
    }
    function success(message){
        return {type:taskcreateConstants.QUESTION_SUCCESS,message};
    }
    function failure(error){
        return{type:taskcreateConstants.QUESTION_ERROR,error};
    }
}