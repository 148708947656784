import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "./index.css";
class PricingCard extends Component {
    render(){
        return (
            <div className="grid">
            {appinfo.Pricing_card.type.map((card,index)=>{
                return(
                    <div className="mycard" style={{backgroundColor:appinfo.secondary_color}}>
                         <div className="title" style={{backgroundColor:card.background_color}}>
                            <h2 style={{color:appinfo.primaryTextColor}}>{card.title}</h2>
                            <img src={card.icon}/>
                         </div>
                        <div class="price">
                         <h4 style={{color:appinfo.primaryTextColor}}><sup style={{color:appinfo.primaryTextColor}}>{card.price_symbol}</sup>{card.price}</h4>
                         <p>{card.duration}</p>
                        </div>
                        <div className="option">
                        <ul>
                       
                            {card.options.map(e=>{
                                return(
                                 <li style={{color:appinfo.primaryTextColor}} ><i class="fa fa-check" aria-hidden="true" style={{color:"green"}}></i> {e}</li>
                            )})}
                        </ul>
                        {card.live && <a href={card.url}><button style={{backgroundColor:appinfo.primary_Color, color:appinfo.secondary_Color,border:"none",textDecoration: "none"}}>GET IT</button></a>}
                        {!card.live && <a><button  style={{backgroundColor:appinfo.primary_Color, color:appinfo.secondary_Color,border:"none",textDecoration: "none"}}>COMING SOON</button></a>}
                        
                       </div>
                    </div>
                )
            })
    }
    </div>
)
}
}
export default PricingCard;
