import React, { Component } from "react";
// import "../../screens/styles.css";
import appinfo from "../../clientsJSON";
import WhatsApp from "../../images/WhatsApp.png";
import "./index.css";

class MayIHelpYou extends Component {
    constructor() {
        super();
        this.state = {
            hover: -1
        }
    }

    render() {
        return (
            <div>
                <div className="call-logo"
                    onMouseEnter={() => { this.setState({ hover: 1 }) }}
                    onMouseLeave={() => { this.setState({ hover: -1 }) }}>
                    {/* <a href={"tel:"+ appinfo.mobile}
                        style={{ textDecoration: "none" }}>
                        <i class="material-icons" style={{color:appinfo.primary_Color,height:64, paddingLeft: 0,padding:"0px 8px"}}>call</i>
                    </a> */}
                    <a href={"https://wa.me/"+appinfo.mobile.replace('+','')} target ="_blank">
                        <img src={WhatsApp} style={{height:64, paddingLeft: 0,padding:"0px 8px"}} alt={"whatsapp"}/>
                    </a>
                </div>
                <div className="call-logo-tool-tip"
                    style={{ display: window.screen.width < 768 ? "none" : this.state.hover == -1 ? "none" : "block" }}
                >
                    <div style={{
                        paddingTop: "20px",
                        opacity: 0.7,
                        fontSize: "12px",
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "center",
                    }}>
                        Need help?
                </div>
                    <div style={{
                        paddingTop: "6px",
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "rgba(0, 0, 0, 0.87)",
                        textAlign: "center",
                    }}>
                        Call <b>{appinfo.mobile}</b>
                    </div>
                </div>
            </div>
        );
    }
}

export default MayIHelpYou;
