import React from 'react';

import appinfo from "../../clientsJSON";
import { MayIHelpYou } from '../../component'
import "./index.css";

;


const Footer =props =>(
    <div className="footerBackground">
        <div className="container footer-content"  >
            <ul>
                <li>
                    <a style={{display:"flex",justifyContent:"center"}} href="/"><img className="logo" src={appinfo.logo_white} alt={appinfo.name}/></a>
                </li>
                <li>
                    <h3 className="bold" style={{textAlign:"left"}}>Contact Us</h3>
                    <p style={{textAlign:"left", maxWidth:300,color:"#979797",fontSize:14}}>
                        <a href= {"tel:" + appinfo.mobile} className="bold" style={{color:"#979797"}}>
                            <span class="material-icons" style={{fontSize:15}} >phone</span> {appinfo.mobile}
                        </a>
                    </p>
                    {appinfo.mobile_sub && 
                    <p style={{textAlign:"left", maxWidth:300,color:"#979797",fontSize:14}}>
                        <a href= {"tel:" + appinfo.mobile_sub} className="bold" style={{color:"#979797"}}>
                            <span class="material-icons" style={{fontSize:15}} >phone</span> {appinfo.mobile_sub}
                        </a>
                    </p>}
                    {appinfo.mobile_optional &&<p style={{textAlign:"left", maxWidth:300,color:"#979797",fontSize:14}}><a href= {"tel:" + appinfo.mobile_optional} className="bold" style={{color:"#979797"}}><span class="material-icons" style={{fontSize:15}} >phone</span> {appinfo.mobile_optional}</a></p>}
                    {appinfo.mobile_optional_sub &&<p style={{textAlign:"left", maxWidth:300,color:"#979797",fontSize:14}}><a href= {"tel:" + appinfo.mobile_optional_sub} className="bold" style={{color:"#979797"}}><span class="material-icons" style={{fontSize:15}} >phone</span> {appinfo.mobile_optional_sub}</a></p>}
                    <p style={{textAlign:"left", maxWidth:300,color:"#979797",fontSize:14}}><a href= {"mailto:" + appinfo.email} className="bold" style={{color:"#979797"}}><span class="material-icons" style={{fontSize:15}}>mail_outline</span> {appinfo.email}</a></p>
                    <h3 className="bold" style={{textAlign:"left"}}>Address</h3>
                    <p style={{textAlign:"left", maxWidth:300,color:"#979797",fontSize:14}}><span class="material-icons" style={{fontSize:15}}>business</span> {appinfo.footer.address[0].address}</p>
                </li>
                <li>
                    {/* <a href="/" className="bold">{appinfo.footer.blog}</a> */}
                    <p><a href="/about-us" className="bold" style={{color:"#979797"}} >{appinfo.footer.about}</a></p>
                    <p><a href="/contact-us" className="bold" style={{color:"#979797"}}>{appinfo.footer.contact}</a></p>
                    <p><a href="/terms-and-conditions" className="bold" style={{color:"#979797"}}>{appinfo.footer.terms}</a></p>
                    <p><a href="/privacy-policy" className="bold" style={{color:"#979797"}}>{appinfo.footer.privacy}</a></p>
                    <p><a href="/refund-policy" className="bold" style={{color:"#979797"}}>{appinfo.footer.refund}</a></p>
                </li>
            </ul>
        </div>
        {/* <MayIHelpYou/> */}
    </div>
)
export default Footer;