import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "../home/index.css";
import {
    Menu,
    Footer,
    ServiceCard
} from "../../component";

class Services extends Component {
    render(){
        document.title = "Services | " + appinfo.name;
       
        return (
            <div>
                <Menu/>
                <div className="mp-container">
                    <h1  style={{textAlign:"center",padding:50}}>Services</h1>
                    {
                        appinfo.services.map( (single,index) => {
                            return <ServiceCard content={single} index={index}/>;
                        })
                    }
                </div>
                <Footer/>
            </div>
        )
    }    
}
export default Services;