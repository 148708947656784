import React,{Component} from "react";
import "./index.css";
import appinfo from "../../clientsJSON";
class SectionCard extends Component {

    render(){
        const { content={}, customStyle={}, index=0, type=[] } = this.props;

        return (
            <div className="section1" 
                style={{paddingTop:32, backgroundColor: index%2 ===0 ? "white": "#F6F6F6"}}>
                {type.includes(1) && <img src={content.image || ""} alt={content.title}/>}
                {type.includes(2) && appinfo.home_sections[index].iconshow && <img src={content.icon} alt={content.title} style={{marginLeft: "auto", marginRight: "auto", display: "flex",backgroundColor: "transparent"}}/>}
                {type.includes(2) && <h3 style={{margin:0}}>{content.title || ""}</h3>}             
                {type.includes(3) && <p style={{padding:"0px 8px",margin:0}}>{content.description || ""}</p>}               
            </div>
        );
    }
}
export default SectionCard;