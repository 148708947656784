import React from "react";
import { connect } from "react-redux";
import { endpointActions } from "../../actions";
import {withRouter} from "react-router-dom";


class RootLoader extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(endpointActions.ping());
  }


  componentDidUpdate(prevProps) {
    const { history } =this.props;
    let newProps = this.props;
    let propsChanged = prevProps.endpoint.status !== newProps.endpoint.status;


    if (newProps.endpoint.status === "PING_SUCCESS" && propsChanged) {
      history.push("/home");
    }
  }

  render() {
    return <div style={{textAlign: "center"}}>Loading...</div>;
  }
}

function mapStateToProps(state) {
  const { endpoint } = state;
  return {
      endpoint
  };
}

export default withRouter(connect(mapStateToProps)(RootLoader));
