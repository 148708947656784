import React,{Component} from "react";
import appinfo from "../../clientsJSON";
import "../home/index.css";
import "./index.scss";

import {
    Menu,
    Footer,
    PricingCard,
    ServiceCard
} from "../../component";

class Pricing extends Component {
    render(){
        document.title = appinfo.pricing.title + " | " + appinfo.name;
       
        return (
           
            <div>
                <Menu/>
             
                {!appinfo.Pricing_card.show &&  <div className="mp-container" style={{textAlign:"center",padding:50}}>
                    <h1>Pricing</h1>
                       <details open>
                        <summary>Plumbing Pricing </summary>
                        <iframe className="summary"  src={appinfo.pricing.airtable1} frameborder="0" onmousewheel=""  width="95%" height="1000" style={{background: "transparent", border: "1px solid #eee"}}></iframe>
                    </details>
                    <details open>
                        <summary >Electrical Pricing</summary>
                        <iframe className="summary" src={appinfo.pricing.airtable2} frameborder="0" onmousewheel="" width="95%" height="1000" style={{background: "transparent", border: "1px solid #eee"}}></iframe>
                    </details> </div>}
                    {/* Google Forms */}
                    {/* <iframe src={appinfo.pricing.googleSheets1} width="60%" height="600"></iframe>
                    <iframe src={appinfo.pricing.googleSheets2} width="60%" height="900"></iframe> */}
                   {appinfo.Pricing_card.show &&<div className="mp-container">
                    <h1  style={{textAlign:"center",padding:50}}>Pricing</h1>
                   <PricingCard/>
                   </div>
                   }
                <Footer/>
            </div>
        )
    }    
}
export default Pricing;
