import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


class Reviews extends Component{
    render(){
        const { content={}, customStyle={} } =this.props;
        return (
            <Carousel autoPlay showStatus={false} scroll_duration={0.1} infiniteLoop showThumbs={false} style={{backgound: "transparent"}}>
                { content.cards.map( single => {
                    return (
                        <blockquote style={{paddingTop:40,paddingBottom:40}}>
                                <p>{single.review}</p>
                                <p><strong>{single.by} - {single.position}, {single.company}</strong></p>  
                        </blockquote>
                        );
                    })
                }
            </Carousel>
        );
    }
}

export default Reviews;

