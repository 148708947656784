import React,{Component} from 'react';
import appinfo from "../../clientsJSON";
import "./index.css";

export default class Menu extends Component{
    render(){
        return(
            <div style={{backgroundColor:appinfo.background_color}}>
                <header className="header">
                    <a href="/"><img className="logo" src={appinfo.logo} alt={"logo"}/></a>
                    <input className="menu-btn" type="checkbox" id="menu-btn" />
                        <label className="menu-icon" for="menu-btn">
                            <span className="navicon"></span>
                        </label>
                        <ul className="menu">
                           { appinfo.header_menu.map( menu => {
                                return <li className="listmenu"><a href={menu.link}>{menu.value}</a></li>;
                                })
                            }
                        </ul>
                </header>
            </div>
        )
    }
}