import React,{Component, useEffect, useState} from "react";
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import "./index.css";
import "./index.scss";
import appinfo from "../../clientsJSON";
import {
    Banner,
    Menu,
    Footer,
    Sections,
    Reviews,
    Seo,
    Input,
} from "../../component";
import Login from "../login";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import {    taskcreateActions } from "../../actions";

const notyf = new Notyf({
    types: [
      {
        type: 'success',
        background: 'green',
        duration:3000,
        dismissible:true,
        position: {
            x: 'center',
            y: 'top',
          },
      },
      {
        type: 'error',
        background: 'indianred',
        duration: 3000,
        dismissible: true,
        position: {
            x: 'center',
            y: 'top',
          },
      }
    ]});

class Home extends Component {
    constructor(){
        super();
        this.state = {
            response: {},
            price_option: null,
            questions: {},
            amount: 0,
            q_id: -1,
            mobileflow:  false,
            task: {},
            processing: false,
            lat: "",
            lon: ""
        }
        this.ClickTask = this.ClickTask.bind(this);
    }

    componentWillMount() {
        const { dispatch, taskcreate } = this.props;
        if(taskcreate.status === "QUESTION_SUCCESS"){
            this.setState({ questions : taskcreate.question.message});
        }else {
            dispatch(taskcreateActions.GetQuestion(this.state.q_id));
        }
    }

    componentWillReceiveProps(nxtProps) {
        const { taskcreate, authentication, dispatch } = this.props;
        let hasTaskPropsChanged = nxtProps.taskcreate.status !== taskcreate.status;
        
        if(hasTaskPropsChanged){
            if(nxtProps.taskcreate.status === "QUESTION_SUCCESS"){
                this.setState({ questions : nxtProps.taskcreate.question.message});
            }
            else if(nxtProps.taskcreate.status === "ADDINGTASK_ERROR"){
                notyf.open({type:"error",message:nxtProps.taskcreate.task.message});
            }
        } 

        let hasPropsChanged = authentication.status !== nxtProps.authentication.status ;

        if(hasPropsChanged){
            if(["SIGNUP_SUCCESS", "LOGIN_SUCCESS"].includes(nxtProps.authentication.status)){
                document.getElementById("finish").checked = true;
                dispatch(taskcreateActions.Addtask(this.state.task));
            }

        }

    }

    renderLocation = (option) => {
        let data = {lat: "", lon: ""};
        navigator.geolocation.getCurrentPosition(function print(response){
            data["lat"] = response.coords.latitude;
            data["lon"] = response.coords.longitude;
        }, function error(){
            alert("You have denied Location permission to serve. Please Allow to experience fine service.");
        });
        return (<div className="questions-btn">
                        <input className="questions" style={{alignContent:'center',backgroundColor:appinfo.primary_Color,color:appinfo.primaryTextColor,borderRadius:20,borderDecoration:"none"}} 
                            type="button" value={"Next"} onClick={()=>this.ClickTask(option[0], "location", data)}/>
                    </div>);
    }

    radiobutton = (options) => {
        return (
            <div>
                {   options.map ( (opt,index) => {
                       return ( 
                       <div  className="radio">
                            <input  className="radio-2" type="radio" name="radio" id={"radio"+index}
                                    value={opt.name}
                                    onClick={()=> this.ClickTask(opt, "radio") }
                                    />
                            <label className="radio-label" for={"radio"+index}> { opt.name } </label>
                       </div> );
                    })
                }
            </div>
        );
    }

    button=(options)=>{
        return (
            <div>
                {
                    options.map(opt =>{
                        return(
                            <div className="questions-btn">
                                <input className="questions" style={{alignContent:'center',backgroundColor:appinfo.primary_Color,color:appinfo.primaryTextColor,borderRadius:20,borderDecoration:"none"}} type="button" id="exampleButton1" value={opt.name} onClick={()=>this.ClickTask(opt,"button")}/>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    renderField = (questions) => {
        if(questions.option[0].type === "navButton")
            return (<div  style={{textAlign: "center"}}>
                        {this.button(questions.option)} 
                    </div>);
        else if(questions.option[0].type === "button") 
            return ( <div  style={{textAlign: "center"}}>
                        {this.radiobutton(questions.option)} 
                    </div>);
        else if(questions.option[0].type === "location")
            return ( <div  style={{textAlign: "center"}}>
                    {this.renderLocation(questions.option)} 
                </div>);
        else if(questions.option[0].type === "text")
            return (<div style={{textAlign: "center"}}>
                        <Input 
                            type="text"
                            value={this.state.textValue}
                            onChange={(e) => this.setState({textValue: e.target.value})}
                            onClick={() => this.ClickTask(questions.option[0], "text")}
                        />
                    </div>);
        else if(questions.option[0].type === "datetime")
            return (<div style={{textAlign: "center"}}>
                        <Input 
                            type="date"
                            value={this.state.textValue}
                            onChange={(e) => this.setState({textValue: e.target.value})}
                            onClick={() => this.ClickTask(questions.option[0], "text")}
                        />
                    </div>);
    }

    ClickTask = (option, type, data = {}) => {
        const { dispatch, authentication } = this.props;
        const { questions } = this.state;
        let resp = this.state.response ;
        let question = questions.question;
        let lat = "";
        let lon = "";
        if(this.state.q_id !== null){
            if(type === "radio") resp[question.title] = option.name;
            else if(type === "location"){
                lat = data.lat;
                lon = data.lon;
            }
            else resp[question.title] = this.state.textValue;
        }
        let location = (lat != "" && lon != "")||(this.state.lat != "" && this.state.lon != "");
        this.setState(
            {   q_id            : option.callback, 
                response        : resp,
                price_option    : option.online_payment === true ?  option.id : this.state.price_option,
                amount          : option.online_payment === true ? this.state.amount + option.amount : this.state.amount,
                textValue       : "",
                lat             : lat === "" ? this.state.lat : lat,
                lon             : lon  === "" ? this.state.lon : lon
            }
        ); 
        if( option.callback > 0 ) dispatch(taskcreateActions.GetQuestion(option.callback));
        else {
            let task = {
                    task_details : Object.keys(resp).length != 0 ? JSON.stringify(resp) : "", 
                    price_option: this.state.price_option,
                    location_provided: location,
                };
            if(location){
                task["lat"] = lat != "" ? lat : this.state.lat;
                task["long"] = lon != "" ? lon : this.state.lon;
            }
            if(authentication.user === undefined)
                this.setState({ mobileflow: true, task: task});
            else dispatch(taskcreateActions.Addtask(task));
        }
    }

    goBack = () => {
        const { questions, response } = this.state;
        const{ dispatch } = this.props;
        let resp = response;
        if(![undefined, "", null].includes(response[questions.question.title])){
            delete resp[questions.question.title];
            this.setState({ response: resp});
        }
        dispatch(taskcreateActions.GetQuestion(questions.question.prev));
    }

    render(){
        document.title = appinfo.name;
        const { questions } = this.state;
        const { taskcreate } = this.props;

        return (
            <div>
                <Menu/>
                <>
                <Banner>
                    {
                        !this.state.mobileflow && taskcreate.status === "QUESTION_SUCCESS" &&
                        (   <div className="container">
                            <div style={{display: "flex",flexDirection:"row"}}>
                                { questions.question.prev &&
                                    <i  className="material-icons" 
                                        style={{cursor:"pointer", marginLeft: 20,marginTop: 20}} 
                                        onClick={this.goBack}
                                    >arrow_back</i>
                                }
                                <p style={{paddingLeft: 20, width: "100%", paddingRight: 20, textAlign: "center",color:appinfo.secondary_Color}}>
                                    {questions.question.title}
                                </p>
                            </div>
                              { this.renderField(questions) }
                            </div>
                        )
                    }
                    {
                        taskcreate.status ==="ADDINGTASK_SUCCESS"&&
                        <div style={{textAlign: "center"}}>
                            <h4 style={{color: appinfo.container.color}}>{appinfo.container.thanksmessage}</h4>
                        </div>
                    }
                    {
                        this.state.mobileflow &&
                         <Login />
                    }

                </Banner>
                {/* Section Rendering */}
                {   
                    appinfo.home_sections.map( (single, index) => {
                        let style = {paddingBottom: 40, paddingTop: 40};
                        style["backgroundColor"] = index%2 !== 0 ? "white" : "#F6F6F6";
                        style["display"] = single.show ? "visible" : "none";

                        return (
                            <div className="About" style={style} >
                                {single.cardType === "section" ?
                                    (   <>
                                       
                                            <h1 style={{textAlign:"center"}}>{single.title}</h1>
                                            {single.iconshow && single.image && <div>
                                            <img src={single.image} alt={single.title} style={{width:"77px" ,height:"77px" , marginLeft: "auto", marginRight: "auto", display: "flex",backgroundColor: "transparent"}}/></div>}
                                            <Sections content={single} index={index}/>
                                        </>
                                    ) : 
                                    (<>
                                            <Reviews content={single} />
                                    </>) 
                                }
                            </div>
                        );
                    })
                }
                </>
                <Seo />
                <Footer/>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { taskcreate, authentication } = state;
    return {
        taskcreate,
        authentication
    };
  }
export default withRouter(connect(mapStateToProps)(Home));
