import React,{Component} from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import {
    Menu,
    Footer
} from "../../component";

class Refund extends Component {
    render(){
        document.title = "Refund Policy | " + appinfo.name;
        return (
            <div>
                <Menu/>
                <div className="mp-container" style={{textAlign:"center",padding:50}}>
                    <h1>{appinfo.policies.refund.title}</h1>
                    <p>{appinfo.policies.refund.description}</p>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Refund;