import React,{Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import WhatsApp from "../../images/WhatsApp.png";
import "../../screens/home/index.css";
import appinfo from "../../clientsJSON";
;

export default class Banner extends Component{
    renderContent = (content) => {
        return (
        <div>
            <h1 style={{color:appinfo.secondary_Color}}>
                {appinfo.container.container_title}
            </h1>
            { content }
              <div style={{display:"flex",flexDirection: "row", textAlign: "center"}}>
                    <div style={{display:"flex",flexDirection: "row", textAlign: "center",margin:"0 auto 0 auto"}}>
                    <a href= {"tel:" + appinfo.mobile} className="bold" style={{textDecoration:"none",color:appinfo.primary_Color,padding:8}}>
                       {/* or <i style={{}} class="material-icons">call</i>  */}
                       or call {appinfo.mobile}
                    </a>
                    <a href={"https://wa.me/"+appinfo.mobile.replace('+','')} target ="_blank">
                        <img src={WhatsApp} style={{height:35, paddingLeft: 0,padding:"0px 8px"}} alt={"whatsapp"}/>
                    </a>
                    {appinfo.mobile_sub && 
                    <a href= {"tel:" + appinfo.mobile_sub} className="bold" style={{textDecoration:"none",color:appinfo.primary_Color,padding:8}}>
                        {/* or <i style={{}} class="material-icons">call</i>  */}
                        {appinfo.mobile_sub}
                    </a>}
                    {appinfo.mobile_sub && 
                    <a href={"https://wa.me/"+appinfo.mobile_sub.replace('+','')} target ="_blank">
                        <img src={WhatsApp} style={{height:35, paddingLeft: 0,padding:"0px 8px"}} alt={"whatsapp"}/>
                    </a>}
                    </div>
              </div>
        </div>
        )
    }

  render(){

      let style={};
      if (appinfo.template === 2) {
          style["minHeight"] = 500;
          style["backgroundImage"] = `url(${appinfo.container.image_path})`;
      }

      return (
        <div>
          { appinfo.template === 1 &&
            <Carousel autoPlay showStatus={false} scroll_duration={0.1} infiniteLoop showThumbs={false}>
                <img src={require("../images/bug.jpg")} alt={"IMAGE 1"}/> 
                <img src={require("../images/bug.jpg")} alt={"IMAGE 2"}/> 
                <img src={require("../images/bug.jpg")} alt={"IMAGE 3"}/> 
            </Carousel>
          }
        
        { appinfo.template < 3 &&
            <div className="mp-container banner" 
                style={appinfo.template === 2 ? style: {}}>
                <div className="mp-card">
                    {this.renderContent(this.props.children)}
                </div>
            </div>
        }
        {
            appinfo.template === 3 &&
            <div className="banner-container-home">
                <div className="banner-container-left-home">
                    {this.renderContent(this.props.children)}
                </div>
                <div className="banner-image">
                    {/* <img src={require("../../images/banner-3.png")}  */}
                    <img src={appinfo.container.image_path} 
                         alt={appinfo.container.image_path} 
                         style={{maxWidth: window.innerWidth >768 ? window.innerWidth/2 : window.innerWidth}}
                    />
                </div>
            </div>
        }
        </div>
      );
    }
}