import React,{Component} from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import {
    Menu,
    Footer
} from "../../component";


class Contact extends Component {
    render(){
        document.title = "Contact Us | " + appinfo.name;

        return (
            <div>
                <Menu/>
                <div className="mp-container">
                    <h1  style={{textAlign:"center",padding:50}}>Contact Us</h1>
                    <div style={{display:"flex",flexWrap:"wrap"}}>
                        { appinfo.footer.address.map( single => {
                            return (
                                <div className="moonadvantage">
                                    <span class="material-icons" style={{fontSize:35,paddingTop:7}}>business</span>
                                    <h3>{single.title}</h3>   
                                    <p>{single.address}</p>          
                                </div> 
                            ); 
                            }
                        )}                       
                    </div>
                    <div className="mp-container" style={{textAlign:"center",padding:80,display:"flex",flexWrap: "wrap",justifyContent: "center"}}>
                        <h3 style={{textAligh:"center", color:"#979797",padding:8}}>
                            <a href= {"tel:" + appinfo.mobile} className="bold" style={{color:"#979797",textDecoration:"none"}}>
                              Mobile:  <span class="material-icons" style={{fontSize:20}} >phone</span> {appinfo.mobile}
                            </a>
                        </h3>
                        {appinfo.mobile_sub && 
                        <h3 style={{textAligh:"center", color:"#979797",padding:6}}>
                            <a href= {"tel:" + appinfo.mobile_sub} className="bold" style={{color:"#979797",textDecoration:"none"}}> <span class="material-icons" style={{fontSize:20}} >phone</span> {appinfo.mobile_sub} </a>
                        </h3>}
                        {appinfo.mobile_optional && 
                        <h3 style={{textAligh:"center", color:"#979797",padding:6}}>
                            <a href= {"tel:" + appinfo.mobile_optional} className="bold" style={{color:"#979797",textDecoration:"none"}}> <span class="material-icons" style={{fontSize:20}} >phone</span> {appinfo.mobile_optional}</a>
                        </h3>}
                        {appinfo.mobile_optional_sub && 
                        <h3 style={{textAligh:"center", color:"#979797",padding:6}}>
                            <a href= {"tel:" + appinfo.mobile_optional_sub} className="bold" style={{color:"#979797",textDecoration:"none"}}> <span class="material-icons" style={{fontSize:20}} >phone</span> {appinfo.mobile_optional_sub}</a>
                        </h3>}
                        <h3 style={{textAligh:"center", color:"#979797",padding:6}}>
                            <a href= {"mailto:" + appinfo.email} className="bold" style={{color:"#979797",textDecoration:"none"}}>  eMail: <span class="material-icons" style={{fontSize:20}} >mail_outline</span> {appinfo.email}</a>
                        </h3>
                    </div>  
                    {appinfo.map && <iframe src={appinfo.map} width="100%" height="450" frameborder={0} style={{border:0}}/>}
                    {/* <p style={{textAlign:"center",fontSize:14}}><a href= {"tel:" + appinfo.mobile} className="bold">{appinfo.mobile}</a></p> */}
                </div>
                <Footer/>
            </div>
        )
    }    
}
export default Contact;