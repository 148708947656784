import React,{Component} from "react";
import appinfo from "../../clientsJSON";
// import "./index.css";
import {
    Menu,
    Footer
} from "../../component";

class Privacy extends Component {
    render(){
        document.title = "Privacy Policy | " + appinfo.name;
        return (
            <div>
                <Menu/>
                <div className="mp-container" style={{textAlign:"center",padding:50}}>
                    <h1>{appinfo.policies.privacy.title}</h1>
                    <p>{appinfo.policies.privacy.description}</p>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Privacy;