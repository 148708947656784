import React from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter,Route,Switch} from "react-router-dom";
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import Home from "./screens/home";
import About from "./screens/about";
import BrokenPage from "./screens/broken_page";
import Services from "./screens/services";
import Contact from "./screens/contact";
import Terms from "./screens/terms";
import Privacy from "./screens/privacy";
import Refund from "./screens/refund";
import RootLoader from "./screens/root_loader";
import Login from "./screens/login";
import Pricing from "./screens/pricing";
import appinfo from "./clientsJSON";

import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const App = ({store}) => {

  return(
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)} >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={RootLoader} />
          <Route exact path="/about-us" component={About}/>
          <Route exact path="/contact-us" component={Contact}/>
          <Route exact path="/home" component={Home}/>
          {appinfo.pricing.show && <Route exact path="/pricing" component={Pricing}/>}
          <Route exact path="/login" component={Login}/>
          <Route exact path="/privacy-policy" component={Privacy}/>
          <Route exact path="/refund-policy" component={Refund}/>
          {appinfo.services_show &&<Route exact path="/services" component={Services}/>}
          <Route exact path="/terms-and-conditions" component={Terms}/>
          <Route component={BrokenPage} />
        </Switch>
      </BrowserRouter>
    </PersistGate>
    </Provider>
  )
}

export default App;